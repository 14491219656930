/* *********************************Request page*********************** */
.requestMainList{
    margin-top: 0px;
  }
  .refreshBtn{
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    /* margin-top: -42px; */
  }
  .minpagrequest {
    overflow: auto;
    height: 400px;
  }
  .rqstMainRow{
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .minpagrequest .cardbody {
    /* height: 100px; */
    box-shadow: #5a5a5a36 3px 0px 4px;
    background-color: #ffffff;
    margin-bottom: 15px;
    width: 100%;
    z-index: 999999;
    border: #ddd solid 1px;
  }
  .rqstacceptbtn {
    font-size: 14px;
    color: rgb(69, 177, 55);
    text-transform: capitalize;
  }
  .rqstacceptpera {
    
  }
  .cancelimgBoxicon {
    max-width: 100px;
    
  }
  .canceldriverimg {
    width: 50px;
   
  }
  .reqstmainonline {
    font-size: 14px;
    text-transform: capitalize;
  }
  .minpagrequest .drivername {
    font-size: 14px;
   /* display: inline-block; */
    /* width: 160px; */
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .drivername2 {
    font-size: 14px;
  /* display: inline-block;
    width: 160px;*/
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .text_elipse {
     /* display: inline-block;  */
    font-size: 14px;
    /* width: 160px; */
    white-space: nowrap;
    overflow: hidden !important;
    /* text-overflow: ellipsis; */
  }
  .text_elipse2 {
    /* display: inline-block; */
    font-size: 14px;
   /* width: 160px;*/
    white-space: nowrap;
    overflow: hidden !important;
    /* text-overflow: ellipsis; */
  }
  .bookinrestname {
     /* margin-top: -8px;  */
    font-size: 14px;
    text-transform: capitalize;
    /* display: inline-block; */
    /* width: 160px; */
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .bookinrestname2 {
    /* margin-top: -8px; */
    font-size: 14px;
    text-transform: capitalize;
    /* display: inline-block; */
   /* width: 160px;*/
    /* white-space: nowrap;
    overflow: hidden !important; */
    text-overflow: ellipsis;
  }
  .bookinrestphone {
    font-size: 14px;
  }
  .minpagrequestbtn {
    color: #fff;
    border-color: #a81317 !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    background-color: #a81317 !important;
  }
  .minpagrequestbtn:hover {
    color: #fff;
    border-color: #a81317 !important;
  }
  .minpagrequestbtn:focus {
    border-color: #a81317 !important;
    background-color: #a81317 !important;
    outline: 0;
    box-shadow: 0 0 0 0rem #a81317 !important;
  }
  .rqstBtnDiv{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .rqstFirstCol{
    text-align: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  /* *******************************End Request page************************ */
  
  /* ***************************Package*********************** */
  
  .packageDrivermainList{
    margin-top: 15px;
    overflow: auto;
  }
  .packageDrivr {
    width: 100%;
    overflow: auto;
    height: 400px;
    /* margin-top: 45px; */
    /* height: 475px; */
  }
  .packageDrivr .cardbody {
    box-shadow: #5a5a5a36 3px 0px 4px;
    background-color: #ffffff;
    margin-bottom: 15px;
    width: 100%;
    z-index: 999999;
    border: #ddd solid 1px;
  }
  .pckgRow{
    margin-bottom: 5px;
  }
  .pckgDriverImgDiv{
    text-align: center;
  }
  .pckgBookIcon{
    font-size: 25px;
    vertical-align: middle;
  }
  .packageDrivr .packageDriverImage {
    margin-top: 5px;
    width: 40px;
      height: 40px;
    /* margin-top: 8px; */
    border-radius: 50%;
    /* margin-bottom: 8px; */
  }
  .packageDrivr .taxibookPackg {
    /* margin-top: 20px; */
  }
  .packageDrivr .taxibookPckgname{
    width: 100px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-size: 14px;
    text-transform: capitalize;
  }
  .packageDrivr .taxibookPckgdata {
    /* width: 100px; */
    /* white-space: nowrap; */
    /* overflow: hidden !important; */
    /* text-overflow: ellipsis; */
    font-size: 14px;
    text-transform: capitalize;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }
  .packageDrivr .taxibookPckgsecpara {
    /* margin-top: 10px; */
    font-size: 14px;
  }
  .packageDrivr .taxibookPckgRate {
    font-size: 14px;
  }
  .packageDrivr .taxibookPckgPrice{
    font-size: 14px;
  }
  .packageDrivr .taxibookPckgMobile{
    font-size: 14px;
  }
  .packageDrivr .taxibookPckgBtn {
    /* margin-top: 15px; */
    border-radius: 33.25px;
    border-color: rgb(69, 177, 55) !important;
  }
  .packageDrivr .taxibookPckgBtn:focus {
    border-color: rgb(69, 177, 55) !important;
    background-color: rgb(69, 177, 55) !important;
  
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(69, 177, 55) !important;
  }
  
  
  /* **************End package******************************** */
  
  .taxiBookingPicker{
    justify-content: center;
    margin: auto;
  }
  
  .taxibooktripimage {
    width: 100px;
    margin-left: 145px;
    /* margin-top: 35px; */
  }
  /* .packageDriverImage{
    height: 100px;
    width:100px;
  } */
  /* .packageDrivermainList .packageDrivr{
    box-shadow: #5a5a5a36 3px 0px 4px;
    background-color: #ffffff;
    margin-bottom: 15px;
    width: 100%;
    z-index: 999999;
    border: #ddd solid 1px;
  } */
  /* .pckgsubmtBtnAlignment{
    justify-content: center;
    align-items: center;
    display: flex;
    
  }
  .pckgsubmtBtnAlignment .packageSubmitBtn{
   
    border-radius: 33.25px;
   
  } */
  /* .packageDriverDetails{
    margin: auto;
    font-size: 14px;
  } */
  .bookTripHead {
    font-size: 1.5rem;
    text-align: center;
    /* margin-left: 75px; */
    margin-bottom: 30px;
    font-weight: bold;
    /* margin-top: 35px; */
    font-family: 'Montserrat', sans-serif !important;
  }
  .nowSection {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .regularNavItem .nav-pills .nav-link {
    border-radius: 5.25rem;
    margin-left: unset !important;
  }
  .nowSection .formcntrl {
    font-size: 16px;
  }
  .btnfrstView {
    width: 232px;
    margin: auto;
    margin-top: 25px;
  }
  .autotaxinearbyfrom .closeIcon {
    float: right;
    margin-top: -35px;
    padding-right: 18px;
   
    margin-right: 173px;
    border-left: #ced4db solid 1px;
    padding: 7px 15px 4px 13px !important;
    z-index: 99999;
    background-color: white;
    border: #ced4db solid 1px;
    position: relative;
  }
  .nowSection .form-label {
    font-size: 16px !important;
    margin-top: 30px;
    display: block;
    margin-bottom: 0.5rem;
  }
  .booktripbtnDiv .taxiBooktripbtn {
    /* margin-left: 35%; */
    /* padding-left: 30px;
    padding-right: 30px; */
    border-color: rgb(69, 177, 55) !important;
    margin: auto;
    justify-content: center;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    width: 134px;
    margin-bottom: 20px;
  }
  .booktripbtnDiv .taxiBooktripbtn:hover {
    color: #f0f5f8;
    border-color: rgb(69, 177, 55) !important;
    /* background-color: #3faded; */
    /* border-color: #3faded; */
    /* box-shadow: #3faded; */
  }
  .booktripbtnDiv .taxiBooktripbtn:active {
    color: #f0f5f8;
    border-color: rgb(69, 177, 55) !important;
    /* background-color: #3faded; */
    /* border-color: #3faded; */
    /* box-shadow: #3faded; */
  }
  .booktripbtnDiv .taxiBooktripbtn:focus {
    border-color: rgb(69, 177, 55) !important;
    background-color: rgb(69, 177, 55) !important;
  
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(69, 177, 55) !important;
  }
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #3faded;
    border-color: #3faded;
    box-shadow: #3faded;
  }
  .nowSection .datepicker input {
    margin: 5px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    background-color: #d2d2d2;
    width: 170px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
    padding-left: 35px;
    height: 30px;
  }
  .nowSection .datepicker input:focus {
    background-color: white;
  }
  .nowSection .formcntrl:active {
    background-color: white;
  }
  .nowSection .form-control:focus {
    /* color: #495057; */
    background-color: #fff !important;
    /* border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); */
  }
  .container bkngFrom {
    width: 75% !important;
  }
  .taxiautoqs {
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    /* margin-bottom: 45px; */
  }
  .autotaxipera {
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin-top: 5px;
    margin-left: -5px;
  }
  .taxiautopera {
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    /* margin-left: 20px; */
    margin-top: 5px;
  }
  
  .nowSection .fieldset {
    min-width: 0;
    padding: 0;
    margin: 15px !important;
    border: 0;
  }
  .nowSection .form-label {
    font-size: 16px !important;
    margin-top: 30px;
    display: block;
    margin-bottom: 0.5rem;
  }
  .booktripregular .bookdrivepickuplab {
    font-size: 16px !important;
    margin-top: 10px;
  
    margin-bottom: 0.5rem;
    font-family: 'Roboto', sans-serif;
  }
  .formlbl {
    font-size: 16px !important;
    margin-top: 10px;
  
    margin-bottom: 0.5rem;
    font-family: 'Roboto', sans-serif;
  }
  .iconstle {
    color: 'gray';
  }
  .car_icon {
    background-color: #fff;
    border-radius: 35px;
    /* border-color:#00abe9; */
    border-color: #fff;
    width: 100px;
  }
  .icon_selected {
    background-color: white;
    border-radius: 35px;
    border: 1px solid #00abe9;
    width: 100px;
    height: 45px;
  }
  
  .icon_selected :focus {
    background-color: #00abe9;
    border-radius: 35px;
    border-color: #fff;
    width: 100px;
  }
  
  .auton_icon {
    background-color: #fff;
    border-radius: 35px;
    border-color: #fff;
    width: 100px;
    margin-left: 10px;
    margin: auto;
    float: right;
  }
  .auton_icon_selected {
    background-color: white;
    border-radius: 35px;
    border: 1px solid #00abe9;
    width: 100px;
    margin-left: 10px;
    margin: auto;
    float: right;
    height: 45px;
  }
  .autotaxinearbyfrom .inputClass {
    margin-top: 15px !important;
    width: 55%;
    /* margin-left: 55px; */
    margin: auto;
  }
  .navSwitch {
    background-color: white;
    border-radius: 35px;
    border: #00abe9;
    width: 100px;
  }
  .navSwitchright {
    background-color: white;
    border-radius: 35px;
    border: #00abe9;
    width: 100px;
    margin-left: 10px;
    margin: auto;
    float: right;
  }
  /* .icon_box .nav-pills .nav-link.active {
    color: #2faded;
    font-family: 'Montserrat', sans-serif;
    background-color: #dedddd08;
    border: 1px solid #2faded;
  }
  
  .icon_box .nav-pills .nav-link {
    border-radius: 0.25rem;
    margin-left: 0px !important;
    font-family: 'Montserrat', sans-serif;
  }
  .icon_box .nav-pills .nav-link {
    display: block;
  }
  .icon_box {
    width: 350px;
  margin-top: -28px;
    margin-bottom: 20px !important;
    margin: auto;
  } */
  /* .icon_img {
    height: 105px;
    margin-top: 5px;
    margin-left: -10px;
  } */
  
  .icon_box {
    margin-top: -31px;
    /* background-color: gray; */
    margin-bottom: 40px !important;
    /* margin: auto; */
    margin-left: -45px;
    margin-right: -44px;
  }
  .autoicon_img {
    height: 25px;
    margin-top: 5px;
    margin-left: -10px;
    margin-bottom: 10px;
  }
  .caricon_img {
    height: 35px !important;
    margin-left: -30px;
    /* margin-top: 5px; */
  }
  .mainTaxipara {
    margin-top: -29px;
    margin-left: 19px;
    /* margin-bottom: 0px !important; */
    /* margin-top: -40px; */
    /* margin-left: 59px; */
    /* margin-left: 7px; */
    font-family: 'Roboto', sans-serif;
    margin-bottom: -7px !important;
    font-size: 1rem;
  
    /* font-weight: bold; */
  }
  .mainRqsticon {
    margin-left: 1px;
    color: black;
    margin-top: 10px;
  }
  
  
  .mainRqstpara {
    margin-top: -23px !important;
    margin-left: 25px !important;
    margin-left: -3px;
    margin-bottom: -7px !important;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    /* font-weight: bold; */
    /* margin-left: -3px; */
    /* margin-top: 4px; */
  }
  
  .mainautopara {
    margin-top: -33px;
    margin-left: 27px;
    /* margin-bottom: 0px !important; */
    /* margin-top: -40px; */
    /* margin-left: 59px; */
    /* margin-left: 7px; */
    margin-bottom: -7px !important;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
  
    /* font-weight: bold; */
  }
  .booktripregular .location-search-input {
    margin-left: 65px;
    margin-right: 65px;
    width: 80% !important;
  }
  .booktripregular .suggestion-item--active {
    background-color: rgb(250, 250, 250);
    cursor: pointer;
  }
  .booktripregular .autocomplete-dropdown-container {
    width: 75%;
    margin-left: 75px;
  }
  .regularlocationbtn {
    background-color: #f3f2f2;
    margin-left: 175px;
    width: 50%;
  }
  
  .regularlocationbtn .btn-link {
    border: 1px solid rgba(110, 108, 108, 0.87);
    font-weight: 400;
    /* color: #007bff; */
    text-decoration: none;
    border: 1px solid;
    text-align: left;
  }
  .regularlocationbtn .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: left;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .booktripregular {
    margin-top: 15px;
  }
  .predictionAlignmentregular {
    position: absolute;
    box-shadow: #00000073 0px 2px 6px;
    margin-right: 160px;
    z-index: 999999;
    max-height: 180px;
    overflow-y: scroll;
  }
  @media (max-width: 640px) and (min-width: 320px) {
    .nowSection {
      margin-top: 20px;
      margin-left: unset;
      margin-right: unset;
    }
  }
  
  /*****************************************check for exist or not*/
  .prdtBox {
    position: absolute;
    box-shadow: #00000073 0px 2px 6px;
    margin-right: 160px;
    z-index: 999999;
    max-height: 180px;
    overflow-y: scroll;
  }
  .nearbybox {
    width: 100%;
    overflow: auto;
    height: 370px;
    /* margin-top: 45px; */
    /* height: 475px; */
  }
  .nearbybox .cardbody {
    box-shadow: #5a5a5a36 3px 0px 4px;
    background-color: #ffffff;
    margin-bottom: 15px;
    width: 100%;
    z-index: 999999;
    border: #ddd solid 1px;
  }
  .imgBox {
    padding: 0px;
    max-width: 100px;
  }
  .nearbybox .driverimg {
    width: 85%;
    height: 82px;
    padding: 0px;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 3.26rem !important;
  }
  .cancelimgBox {
    max-width: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  
  .nearbybox .bookinear {
    /* border-radius: 0px; */
    /* margin-right: -30px; */
    /* margin-top: 19px; */
    height: 51px;
    width: 51px;
    border-radius: 36px;
    margin-top: -73px;
    border-color: rgb(69, 177, 55) !important;
    margin-right: -8px;
  }
  
  .nearbybox .bookinear i {
    font-size: 22px;
  }
  /* .nearbybox .drivername {
    padding-top: 5px;
    font-size: 14px;
    margin-left: -76px;
  } */
  .bkngFrom .text-danger {
    color: #dc3545 !important;
    font-size: 12px;
    margin-bottom: 0px;
    padding-left: 170px;
  }
  
  /* .text_elipse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 110px;
  } */
  /* .tab-content > .tab-pane {
    display: inline;
  } */
  .mainnearbylist {
    margin-top: 15px;
    overflow: auto;
  }
  .mainlookingtaxi {
    margin-left: 30px;
  }
  
  
  
  .mainpagebookcancel {
    background-color: red;
    color: white;
  }
  
  
  
  .minpagrequest .reqstmodelmake {
    padding-top: 0px;
  
    font-size: 14px;
    margin-left: -87px;
  }
  
  .autotaxinearbyfrom .btn-light:focus {
    box-shadow: none !important;
  }
  .autotaxinearbyfrom .btn-light:hover {
    border-color: #00000040 !important;
  }
  
  /* *******************No data image****************** */
  .taxiNoDataImg{
    width:50px;
    height: 55px;
  }
  .taxiNoDataImgDiv{
    text-align: center;
    /* margin-top: 30px; */
  }
  .taxiNoDataTextDiv{
    text-align: center;
    /* margin-bottom: 30px; */
  }
  
  .spclPckgList{
    text-transform: capitalize;
  }
  
  
  /* ************************Media query*********************** */
  
  @media (max-width: 991px) and (min-width: 678px) {
    .opnAgntlookingauto {
      margin-left: -20px;
      margin-left: auto;
      margin-right: auto;
    }
    .mainRqsticon {
      margin-left: 7px;
      color: black;
      margin-top: 10px;
    }
    .mainRqstpara {
      margin-left: 30px;
    }
  }
  @media (max-width: 767px) and (min-width: 576px) {
    .opnAgntlookingauto {
      margin-left: -20px;
      /* margin-left: auto; */
      margin-right: auto;
    }
    .autotaxinearbyfrom .closeIcon {
      float: right;
      margin-top: -38px;
      padding-right: 18px;
      margin-right: 48px;
    }
    .mainlookingtaxi {
      margin-left: 32px;
    }
    /* .bookinear {
      margin-top: 75px;
    } */
    .nearbybookbtn {
      margin-top: 75px;
    }
  }
  @media (max-width: 575px) and (min-width: 524px) {
    .opnAgntlookingauto {
      margin-left: -20px;
      /* margin-left: auto; */
      /* margin-right: auto; */
    }
    .mainlookingtaxi {
      margin-left: -24px;
      /* margin-left: -96px; */
    }
    .taxiboohingselctvechi {
      margin-left: 136px;
    }
    .nearbybookbtn {
      margin-top: 85px;
      margin-right: 150px;
    }
  }
  
  @media (max-width: 575px) and (min-width: 549px) {
    .taxiboohingselctvechi {
      margin-left: 138px;
    }
  }
  @media (max-width: 523px) and (min-width: 493px) {
    .opnAgntlookingauto {
      margin-left: -20px;
      /* margin-left: auto; */
      /* margin-right: auto; */
    }
    .mainlookingtaxi {
      margin-left: -25px;
    }
    .taxiboohingselctvechi {
      margin-left: 162px;
    }
  }
  @media (max-width: 522px) and (min-width: 487px) {
    .opnAgntlookingauto {
      /* margin-left: -33px; */
      /* margin-left: auto; */
      /* margin-right: auto; */
    }
    .mainlookingtaxi {
      margin-left: -25px !important;
    }
  }
  @media (max-width: 490px) and (min-width: 320px) {
    .autoicon_img {
      height: 25px;
      margin-top: 5px;
      margin-left: 11px;
      margin-bottom: 10px;
    }
    /* .text_elipse {
     
      width: 81px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    } */
    .mainautopara {
      margin-left: 57px;
    }
    .mainRqstpara {
      margin-left: 58px !important;
    }
    .mainRqsticon {
      margin-left: 20px;
      color: black;
      margin-top: 10px;
    }
    .caricon_img {
      height: 35px !important;
      margin-left: -25px;
    }
    .mainTaxipara {
      margin-left: 30px;
    }
    .opnAgntlookingauto {
      margin-left: 6px !important;
    }
    .taxiboohingselctvechi {
      margin-left: 35px;
      height: 140px;
    }
    .autotaxinearbyfrom .closeIcon {
      float: right;
      margin-top: -35px;
      /* padding-right: 18px; */
      margin-right: 140px;
      border-left: #ced4db solid 1px;
      /* padding: 7px 15px 4px 13px !important; */
      z-index: 1;
      background-color: white;
      border: #ced4db solid 1px;
      position: relative;
    }
    .autotaxinearbyfrom .inputClass {
      margin-top: 15px;
      width: 100%;
      /* margin-left: -14px; */
    }
    .regularlocationbtn {
      background-color: #f3f2f2;
      margin-left: 4px;
      width: 70%;
    }
  
    .nearbybox .bookinear {
      /* border-radius: 0px; */
      margin-right: -110px;
      /* margin-top: 19px; */
      height: 51px;
      width: 51px;
      margin-top: -35px;
      border-radius: 36px;
      margin-top: -15px;
      border-color: rgb(69, 177, 55) !important;
      margin-bottom: 10px;
    }
    /* .minpagrequest .cardbody {
      box-shadow: #5a5a5a36 3px 0px 4px;
      background-color: #ffffff;
      margin-bottom: 15px;
      
      height: 200px;
      z-index: 999999;
      border: #ddd solid 1px;
    } */
    .booktripregular {
      margin-top: 15px;
      /* height: 300px; */
    }
    /* .minpagrequest .drivername {
      font-size: 14px;
      margin-left: -76px;
      margin-top: 80px;
      margin-left: -98px;
    } */
    /* .minpagrequestbtn {
      margin-left: -26px;
      margin-top: -2px;
    } */
    /* .text_elipse {
      margin-left: -28px;
    } */
    /* .bookinrestname {
      margin-left: -27px;
    } */
    /* .bookinrestphone {
      margin-left: -26px;
    } */
  }
  @media (max-width: 575px) and (min-width: 486px) {
    /* .minpagrequest .cardbody {
      box-shadow: #5a5a5a36 3px 0px 4px;
      background-color: #ffffff;
      margin-bottom: 15px;
  
      height: 130px;
      z-index: 999999;
      border: #ddd solid 1px;
    } */
    /* .minpagrequest .drivername {
      font-size: 14px;
      margin-left: -45px;
      margin-top: 10px;
    } */
    /* .text_elipse {
      margin-left: 27px;
    } */
    /* .minpagrequestbtn {
      margin-left: 27px;
      margin-top: -3px;
    } */
    /* .canceldriverimg {
      width: 50px;
      margin-right: 40px;
    } */
    /* .reqstmainonline {
      font-size: 14px;
      margin-left: 32px;
      margin-bottom: 0rem !important;
    } */
    /* .bookinrestname {
      margin-left: 26px;
    } */
    /* .bookinrestphone {
      margin-left: 26px;
    } */
    /* .rqstacceptpera {
      margin-bottom: 0rem !important;
      margin-top: 5px !important;
      margin-left: -12px;
      text-transform: capitalize;
    } */
  }
  @media (max-width: 991px) and (min-width: 768px) {
    /* .minpagrequest .cardbody {
      box-shadow: #5a5a5a36 3px 0px 4px;
      background-color: #ffffff;
      margin-bottom: 15px;
     
      height: 220px;
      z-index: 999999;
      border: #ddd solid 1px;
    } */
    .nearbybox .driverimg {
      width: 85% !important;
      height: 82px;
      padding: 0px;
      margin-top: 10px;
      margin-left: 10px;
    }
    /* .nearbybox .cardbody {
      height: 215px;
    } */
    .nearbybox .bookinear {
      height: 51px;
      width: 51px;
      border-radius: 36px;
      margin-top: 8px !important;
      border-color: rgb(69, 177, 55) !important;
      margin-right: 50px;
    }
  
    /* .minpagrequest .drivername {
      font-size: 14px;
      margin-left: -76px;
      margin-top: 80px;
      margin-left: -98px;
    } */
    /* .minpagrequestbtn {
      margin-left: -110px;
      margin-top: 50px;
    } */
    /* .text_elipse {
      margin-left: -25px;
    } */
    /* .bookinrestname {
      margin-left: -27px;
    } */
    
    .autotaxinearbyfrom .closeIcon {
      margin-right: 0px;
    }
    .autotaxinearbyfrom .inputClass {
      margin-top: 15px;
      width: 100%;
      margin-left: 0px;
    }
    /* .text_elipse {
      
      width: 78px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    } */
    .regularlocationbtn {
      background-color: #f3f2f2;
      /* background-color: rgba(201, 194, 194, 0.54); */
      margin-left: 6px;
      width: 70%;
    }
    .taxibookingmainlook hr {
      margin-top: 0px !important;
      margin-bottom: 1rem;
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      width: 105% !important;
      margin-left: -17px !important;
    }
    
  }
  @media (max-width: 575px) and (min-width: 491px) {
    .autotaxinearbyfrom .inputClass {
      margin-top: 15px;
      width: 99%;
      margin-left: -8px;
    }
    .autotaxinearbyfrom .closeIcon {
      float: right;
      margin-top: -38px;
      padding-right: 18px;
      margin-right: 4px;
      border-left: #ced4db solid 1px;
      padding: 7px 15px 4px 13px !important;
      z-index: 99999;
      background-color: white;
      border: #ced4db solid 1px;
      position: relative;
    }
    .regularlocationbtn {
      background-color: #f3f2f2;
      margin-left: 0px;
      width: 75%;
    }
  }
  .autotaxinearbyfrom .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #0000003d;
    outline: 0;
    box-shadow: 0 0 0 0.2rem white;
  }
  
  /* ========================================taxiautoreqest============================================= */
  
  .taxibookingmainlook .nav-pills .nav-link.active {
    color: white;
    background-color: #0d334a;
    height: 40px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    margin-left: -8px;
  }
  .taxibookingmainlook hr {
    margin-top: -15px;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 96%;
    margin-left: 25px;
  }
  .taxibookingmainlook .nav-pills .nav-link {
    border-radius: 0px;
    /* margin-left: 25px; */
  }
  .taxibookingmainlook {
    margin-left: -23px;
  }
  .taxibookinglookrequests {
    margin-left: -15px;
    font-size: 16px;
  }
  .taxibookinglooktaxi {
    margin-left: 5px;
    font-size: 16px;
    /* color: white; */
  }
  .taxibookinglookauto {
    margin-left: 4px;
    font-size: 16;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    .taxibookingmainlook {
      margin-left: 20px;
    }
    .taxibookinglooktaxi {
      text-align: center;
      font-size: 16px;

    }
    .taxibookinglookauto {
      text-align: center;
      font-size: 16;
    }
    .taxibookinglookrequests {
      margin-left: 18px;
      text-align: center;
      font-size: 16px;
    }
    /* .taxiformsec .form-label {
    
      margin-top: 10px;
     
      margin-bottom: 1.5rem;
      font-size: 1rem !important;
      font-family: 'Roboto', sans-serif;
      text-align: center !important;
      width: 61% !important;
      margin-left: 55px !important;
    } */
  }
  @media (max-width: 767px) and (min-width: 320px) {
    .taxibookinglooktaxi {
      text-align: center;
      font-size: 16px;
    }
    .taxibookinglookauto {
      text-align: center;
      font-size: 16;
    }
    .taxibookinglookrequests {
      text-align: center;
      margin-left: 10px;
      font-size: 16px;
    }
    .taxibookingmainlook hr {
      margin-top: 0px !important;
      margin-bottom: 1rem;
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      width: 96%;
      margin-left: 25px;
    }
    .bkngFrom .text-danger {
      color: #dc3545 !important;
      font-size: 12px;
      margin-bottom: 0px;
      padding-left: 85px;
  }
    /* .chngNmbrpara {
      text-align: center !important;
      color: #308cc1;
      cursor: pointer;
  
      font-size: 14px;
      margin-top: 20px;
      font-family: 'Roboto', sans-serif;
  
      margin: auto;
      justify-content: center;
      display: flex;
    } */
    /* .rsndotppara {
      text-align: center;
      color: #308cc1;
      cursor: pointer;
  
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
      margin-top: 15px !important;
      margin: auto;
      justify-content: center;
      display: flex;
    } */
    .Taxiotpbuttnsub {
      padding-left: 25px;
      padding-right: 25px;
      border-color: rgb(69, 177, 55) !important;
      margin: auto;
      justify-content: center;
      margin-left: -45px;
      margin-bottom: 30px;
    }
  
  }
  @media (max-width: 767px) and (min-width: 320px) {
    /* .taxiformsec .form-label {
      width: 45% !important;
      margin-left: 94px !important;
    } */
  }
  @media (max-width: 575px) and (min-width: 477px) {
    /* .taxiformsec .form-label {
      width: 45% !important;
      margin-left: 94px !important;
    } */
  }
  @media (max-width: 476px) and (min-width: 320px) {
    /* .taxiformsec .form-label {
      width: 100% !important;
      margin-left: 0px !important;
    } */
  }
  .nearbybox .bookinear {
    /* border-radius: 0px; */
    /* margin-right: -30px; */
    /* margin-top: 19px; */
    height: 51px;
    width: 51px;
    border-radius: 36px;
    margin-top: -73px;
    border-color: rgb(69, 177, 55) !important;
  }
  @media (max-width: 500px) and (min-width: 320px) {
    .nearbybookbtn {
      margin-top: 75px;
      margin-right: 110px;
      margin-bottom: -17px;
    }
    .nearbybox .driverimg {
      width: 85%;
      height: 82px;
      padding: 0px;
      margin-top: 10px;
      margin-left: 84px;
    }
  }
  @media (max-width: 500px) and (min-width: 478px) {
    .nearbybox .driverimg {
      width: 85%;
      height: 82px;
      padding: 0px;
      margin-top: 10px;
      margin-left: 10px !important;
    }
  }
  @media (max-width: 399px) and (min-width: 385px) {
    .nearbybox .driverimg {
      width: 85%;
      height: 82px;
      padding: 0px;
      margin-top: 10px;
      margin-left: 10px !important;
    }
  }
  
  /* ============================================nearbyauto======================================= */
  
  .taxibooknearvechimg {
    width: 76px;
    margin-top: 8px;
    border-radius: 62.25px;
    margin-bottom: 8px;
  }
  .taxibooknearvechmainpera {
    margin-top: 20px;
  }
  .taxibooknearvechname {
    width: 100px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-size: 14px;
    text-transform: capitalize;
  }
  .taxibooknearvechmodel {
    font-size: 14px;
  }
  .taxibooknearvechdata {
    width: 100px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-size: 14px;
    text-transform: capitalize;
  }
  .taxibooknearvechsecpera {
    margin-top: 10px;
    font-size: 14px;
  }
  .taxibooknearvechmainbtn {
    margin-top: 20px;
    border-radius: 33.25px;
    border-color: rgb(69, 177, 55) !important;
  }
  .taxibooknearvechmainbtn:focus {
    border-color: rgb(69, 177, 55) !important;
    background-color: rgb(69, 177, 55) !important;
  
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(69, 177, 55) !important;
  }
  
  .taxipackagedatepicker label {
    display: -webkit-box;
    margin-bottom: 0.5rem;
    margin-top: 10px;
  }
  
  
  @media (max-width: 991px) and (min-width: 768px) {
    .taxibooknearvechmainbtn {
      margin: auto;
      justify-content: center;
      display: flex;
      margin-bottom: 10px;
    }
    .taxibooknearvechimg {
      margin-bottom: 8px;
      justify-content: center;
      display: flex;
      margin: auto;
      margin-top: 10px;
    }
    /* .taxiformsec .errormessage {
      color: red;
      margin-left: 84px;
      font-size: 13px;
    } */
    /* ***************Package section************* */
    .packageDrivr .taxibookPckgname{
      width: 100%;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      font-size: 14px;
      text-transform: capitalize;
      text-align: center;
    }
    .packageDrivr .taxibookPckgMobile{
      font-size: 14px;
      text-align: center;
    }
    .packageDrivr .taxibookPckgPrice{
      font-size: 14px;
      text-align: center;
    }
    .packageDrivr .taxibookPckgRate {
      font-size: 14px;
      text-align: center;
    }
    .taxibookPckgBtnCol{
      margin-top: 5px;
      text-align: center;
    }
  
    /* ********************************************** */
  
    /* ******************************Request section****************** */
    .rqstBtnDiv{
      margin: 0px;
      position: relative;
      top: 0%;
      left: 0%;
      transform: none;
      text-align: center;
      margin-top: 10px;
    }
    .rqstFirstCol{
      margin: 0px;
      position: relative;
      top: 0%;
      left: 0%;
      transform: none;
    }
    .cancelimgBoxicon {
      max-width: none;
    }
    .rqstSecndCol{
      text-align: center;
    }
    .text_elipse {
      display: inline-block;
      font-size: 14px;
     /* width: 160px;*/
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
   
  
    /* ****************************************************************** */
  }
  @media (max-width: 575px) and (min-width: 320px) {
    .taxibooknearvechmainbtn {
      margin: auto;
      justify-content: center;
      display: flex;
      margin-bottom: 10px;
    }
    .taxibooknearvechimg {
      margin-bottom: 8px;
      justify-content: center;
      display: flex;
      margin: auto;
      margin-top: 10px;
    }
    /* .taxiformsec .errormessage {
      color: red;
      margin-left: 94px;
      font-size: 13px;
    } */
     /* ***************Package section************* */
     .packageDrivr .taxibookPckgname{
      width: 100%;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      font-size: 14px;
      text-transform: capitalize;
      text-align: center;
    }
    .packageDrivr .taxibookPckgMobile{
      font-size: 14px;
      text-align: center;
    }
    .packageDrivr .taxibookPckgPrice{
      font-size: 14px;
      text-align: center;
    }
    .packageDrivr .taxibookPckgRate {
      font-size: 14px;
      text-align: center;
    }
    .taxibookPckgBtnCol{
      margin-top: 5px;
      text-align: center;
    }
  
    /* ********************************************** */
    /* ******************************Request section****************** */
    .rqstBtnDiv{
      margin: 0px;
      position: relative;
      top: 0%;
      left: 0%;
      transform: none;
      text-align: center;
      margin-top: 10px;
    }
    .rqstFirstCol{
      margin: 0px;
      position: relative;
      top: 0%;
      left: 0%;
      transform: none;
    }
    .cancelimgBoxicon {
      max-width: none;
    }
    .rqstSecndCol{
      text-align: center;
    }
    .text_elipse {
      display: inline-block;
      font-size: 14px;
     /* width: 160px;*/
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  
    /* ****************************************************************** */
  
    /* *******************errormessage***************** */
    .bkngFrom .text-danger {
      padding-left: 0px;
      margin-left: 0px;
    }
   
  }
  @media (max-width: 767px) and (min-width: 576px) {
    .taxibooknearvechmainbtn {
      margin-top: 20px;
      margin-left: -12px;
    }
    /* .taxiformsec .errormessage {
      color: red;
      margin-left: 133px;
      font-size: 13px;
    } */
     /* ***************Package section************* */
     .taxibookPckgBtnCol{
      margin-top: 5px;
      text-align: center;
    }
  
    /* ******************************Request section****************** */
    .rqstBtnDiv{
      margin: 0px;
      position: relative;
      top: 0%;
      /* left: 0%; */
      transform: none;
      text-align: center;
      margin-top: 5px;
    }
    .rqstFirstCol{
      margin: 0px;
      position: relative;
      top: 0%;
      left: 0%;
      transform: none;
    }
    .cancelimgBoxicon {
      max-width: none;
    }
    /* .rqstSecndCol{
      text-align: center;
    } */
    .text_elipse {
      /* display: inline-block; */
      font-size: 14px;
     /* width: 160px;*/
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    .bookinrestname {
      /* margin-top: -8px; */
      font-size: 14px;
      text-transform: capitalize;
      display: block;
     /* width: 160px;*/
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      /* background-color: #a81317; */
    }
    .minpagrequest .drivername {
      font-size: 14px;
    /*  display: block;*/
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  
    /* ****************************************************************** */
  
  }
  @media (max-width: 991px) and (min-width: 768px) {
    .bkngFrom .text-danger {
      padding-left: 0px;
      /* margin-left: -5px; */
    }
    /* .datepickerpackage {
      width: 117% !important;
      padding-bottom: 5px;
      padding: 5px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    } */
    .taxipackagedatepicker {
      margin-left: -14px;
    }
    /* .taxipackagetimepicker .form-control {
      display: block;
      width: 107%;
    } */
    .taxipackagetimepicker {
      margin-left: -14px;
      margin-right: -17px;
    }
  }
  @media (max-width: 767px) and (min-width: 576px) {
    /* .taxipackagedatepicker {
      margin-left: 0px;
    } */
  
    .taxipackagetimepicker {
      /* margin-top: 4px; */
    }
  
  }
  
  
  @media (max-width: 575px) and (min-width: 320px) {
    .taxipackagetimepicker .form-control {
      display: block;
      width: 102%;
    }
    .taxipackagetimepicker {
      /* margin-left: -25px;
      margin-top: 4px; */
      margin-right: -10px;
    }
    /* .datepickerpackage {
      width: 100%;
      padding-bottom: 5px; */
    /* padding: 5px; */
    /* border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    } */
   
  
    .taxipackagedatepicker {
      /* margin-left: -26px; */
      margin-left: -13px;
    }
    .requestTab {
      font-family: 'Montserrat', sans-serif;
    }
  }
  /* ========================================================================= */
  .nav-tabs .nav-link.active {
    /* color: #2f353a;
    background: red;
    border-color: red;
    border-bottom-color: red; */
    color: black;
    background: white;
    border-bottom: 5px solid #0d334a;
}
.nav-tabs .nav-link.active:focus {
  /* background: red;
  border-color: red;
  border-bottom-color: red */
  color: black;
  background: white;
  border-bottom: 5px solid #0d334a;
  /* color: #fff; */
}
.nav-tabs .nav-link {
  border: 0px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs {
  border-bottom: 0px solid #c8ced3;
 
  /* margin-bottom: 10px; */
}

@media (max-width: 1199px) and (min-width: 992px) {
  .autotaxinearbyfrom .closeIcon {
    float: right;
    margin-top: -35px;
    padding-right: 18px;
    margin-right: 114px;
    border-left: #ced4db solid 1px;
    padding: 7px 15px 4px 13px !important;
    z-index: 99999;
    background-color: white;
    border: #ced4db solid 1px;
    position: relative;
}
}