/* .cardMainprofile {
  width: 30rem;
  float: right;
  margin-top: 180px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 30px;
  background-color: #f3f2f2;
} */
.fileerrormessage {
  color: red;
  padding-left: 50px;
  font-size: 12px;
}
.bgclrRegister {
  background-image: url("../../assets/img/rydz web home bg1.jpg");
  float: none;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: white !important;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}
.cardMainprofile {
  width: 30rem;
  float: right;
  margin-top: 30px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 30px;
  background-color: #f3f2f2;
}
.profilehead {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}
.btnprofile .forgotbutton {
  background-color: #3faded;
  width: 100px;
  /* float: center; */
  border-color: #3faded;
  justify-content: center;
  align-items: center;
  /* margin-right: 40px; */
  /* margin-top: 10px; */
}
.btnprofile .forgotbutton :hover {
  background-color: #3faded !important;
  width: 100px;
  /* float: center; */
  border: #3faded;
}
.main_columProfile {
  justify-content: center;
  align-items: center;
  display: flex;
}
.profileform .formcntrl {
  font-size: 14px;

  background-color: white !important;
}
.profileFormGrid .form-control {
  font-size: 14px;
  border-radius: 0.25rem;
  background-color: white;
}
.profileFormGrid .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #00000040 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #f3f2f2;
}
.profileFormGrid .fieldset {
  margin-left: 40px;
  margin-right: 40px;
}
.profileFormGrid .errormessage {
  margin-bottom: 0px;
  margin-left: 0px;
}
/* .agentprofileImg {
  text-align: center;
  margin: 5px 15px;
  height: 200px;
  width: 500px;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 5px solid gray;
  border-bottom: 5px solid gray;
} */

/* button */
.btnagentprofile {
  width: 185px;
  display: "flex";
  /* background-color: #3faded; */
  border-color: rgb(69, 177, 55) !important;
  margin-top: 1rem;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}
.btnagentprofile:hover {
  background-color: rgb(69, 177, 55);
  border-color: rgb(69, 177, 55) !important;
}
.btnagentprofile:active {
  background-color: rgb(69, 177, 55);
  border-color: rgb(69, 177, 55) !important;
}
.btnagentprofile:focus {
  border-color: rgb(69, 177, 55) !important;
  background-color: rgb(69, 177, 55) !important;

  outline: 0;
  box-shadow: 0 0 0 0rem rgb(69, 177, 55) !important;
}
.btnAlignprofile {
  justify-content: center;
  align-items: center;
  display: flex;
}
.formGridState .form-control {
  background-color: white;
}
.previewimgProfile {
  /* margin-top: 20px; */
  padding: 10px;
}
.previewimgProfile .prflImg {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 43px;
  font-size: 14px;
  color: white;
}
.imgpreviewDiv img {
  width: 100%;
  vertical-align: middle;
  /* border-style: none; */
}
.imgpreview .form-control {
  /* margin: 10px; */
  width: 100%;
  margin-left: 21px;
  /* margin-top: 25px; */
}
.imgpreview .form-group {
  /* margin: 10px; */
  width: 100%;
  /* margin-left: 29px; */
  /* margin-top: 25px; */
}
.preview .form-group {
  width: 30%;
}
.agentprofileImg {
  text-align: center;
  margin: 5px 15px;
  /* height: 200px; */
  width: 500px;
  margin-left: inherit;
  /* border-left: 1px solid gray; */
  /* border-right: 1px solid gray; */
  /* border-top: 5px solid gray; */
  /* border-bottom: 5px solid gray; */
}
.fileInput {
  border-bottom: 4px solid lightgray;
  border-right: 4px solid lightgray;
  border-top: 1px solid black;
  border-left: 1px solid black;
  padding: 10px;
  margin: 15px;
  cursor: pointer;
}
.imgpreviewDiv .img {
  width: 100% !important;
}
/* <<<<<<<<<<<<>>>>>>>>>>>>>>>card responsive>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<< */
@media (max-width: 991px) and (min-width: 768px) {
  .cardMainprofile {
    /* margin-right: -106px; */
  }
}

@media (max-width: 767px) and (min-width: 550px) {
  .cardMainprofile {
    width: 22rem;
    /* margin-right: 93px; */
  }
  .cardMainprofile .fieldset {
    margin-left: 0px;
    margin-right: 0px;
  }
  .previewimgProfile .prflImg {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -10px;
    font-size: 14px;
  }
  .imgpreview .form-control {
    /* margin: 10px; */
    width: 100%;
    margin-left: -20px;
    /* margin-top: 25px; */
  }
  .fileerrormessage {
    color: red;
    padding-left: 0px !important;
    font-size: 12px;
  }
}

@media (max-width: 549px) and (min-width: 520px) {
  .cardMainprofile {
    /* margin-right: 74px; */
    /* margin-left: 1px; */
    width: 22rem;
  }
  .cardMainprofile .fieldset {
    margin-left: 0px;
    margin-right: 0px;
  }
  .previewimgProfile .prflImg {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -10px;
    font-size: 14px;
  }
  .imgpreview .form-control {
    /* margin: 10px; */
    width: 100%;
    margin-left: -20px;
    /* margin-top: 25px; */
  }
  .fileerrormessage {
    color: red;
    padding-left: 0px !important;
    font-size: 12px;
  }
}

@media (max-width: 519px) and (min-width: 475px) {
  .cardMainprofile {
    width: 20rem;
    /* margin-right: 0px; */
    /* margin-left: 19px; */
    float: inherit;
  }
  .cardMainprofile .fieldset {
    margin-left: 0px;
    margin-right: 0px;
  }
  .previewimgProfile .prflImg {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -10px;
    font-size: 14px;
  }
  .imgpreview .form-control {
    /* margin: 10px; */
    width: 100%;
    margin-left: -20px;
    /* margin-top: 25px; */
  }
  .fileerrormessage {
    color: red;
    padding-left: 0px !important;
    font-size: 12px;
  }
  .imgpreviewDiv img {
    padding-top: 10px;
  }
}

@media (max-width: 474px) and (min-width: 425px) {
  .cardMainprofile {
    /* margin-left: 23px; */
    width: 20rem;
    float: inherit;
  }
  .cardMainprofile .fieldset {
    margin-left: 0px;
    margin-right: 0px;
  }
  .previewimgProfile .prflImg {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -10px;
    font-size: 14px;
  }
  .imgpreview .form-control {
    /* margin: 10px; */
    width: 100%;
    margin-left: -20px;
    /* margin-top: 25px; */
  }
  .fileerrormessage {
    color: red;
    padding-left: 0px !important;
    font-size: 12px;
  }
  .imgpreviewDiv img {
    padding-top: 10px;
  }
  .btnagentprofile {
    width: 100px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .bgclrRegister {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .profilehead {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}

@media (max-width: 424px) and (min-width: 375px) {
  .cardMainprofile {
    width: 20rem;
    /* margin-right: 7px; */
    float: inherit;
    /* margin: auto; */
  }
  .btnagentprofile {
    width: 100px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .cardMainprofile .fieldset {
    margin-left: 0px;
    margin-right: 0px;
  }
  .previewimgProfile .prflImg {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -10px;
    font-size: 14px;
  }
  .imgpreview .form-control {
    /* margin: 10px; */
    width: 100%;
    margin-left: -20px;
    /* margin-top: 25px; */
  }
  .fileerrormessage {
    color: red;
    padding-left: 0px !important;
    font-size: 12px;
  }
  .imgpreviewDiv img {
    padding-top: 10px;
  }
  .bgclrRegister {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .profilehead {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}

@media (max-width: 374px) and (min-width: 290px) {
  .cardMainprofile {
    width: 18rem !important;
    /* margin-right: -5px; */
    float: inherit;
    /* margin-left: -15px; */
    /* margin: auto; */
  }
  .btnagentprofile {
    width: 100px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .cardMainprofile .fieldset {
    margin-left: 0px;
    margin-right: 0px;
  }
  .previewimgProfile .prflImg {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -10px;
    font-size: 14px;
  }
  .imgpreview .form-control {
    /* margin: 10px; */
    width: 100%;
    margin-left: -20px;
    /* margin-top: 25px; */
  }
  .fileerrormessage {
    color: red;
    padding-left: 0px !important;
    font-size: 12px;
  }
  .imgpreviewDiv img {
    padding-top: 10px;
  }
  .bgclrRegister {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .profilehead {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}

/* <<<<<<<<<<<<>>>>>>>>>>>>>>>upload image responsive>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<< */
@media (max-width: 767px) and (min-width: 400px) {
  .agentprofileImg {
    width: 50% !important;
    /* margin-top: -109px; */
    /* margin-left: 75%; */
  }
  .imgpreviewDiv img {
    padding-top: 10px;
  }
}
@media (max-width: 399px) and (min-width: 300px) {
  .agentprofileImg {
    width: 50% !important;
    /* margin-top: -109px; */
    /* margin-left: 75%; */
  }
  .imgpreviewDiv img {
    padding-top: 10px;
  }
}
.loginForm .form-control {
  border-radius: 0.25rem;
}
.loginForm .form-control {
  display: block;
  width: 60%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff !important;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.loginheader {
  font-size: 26px;
  color: white;
  margin-bottom: 15px;
  font-weight: 600 !important;
  border-bottom: 3px solid;
  /* border-bottom-width: 5px; */
  width: 55%;
  line-height: 45px;
  border-bottom-color: rgb(69, 177, 55) !important;
}
.file input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}
.fleetPrflImg .file {
  position: relative;
  /* overflow: hidden; */
  /* width: 30%; */
  border: none;
  border-radius: 0;
  font-size: 14px;
  background: rgba(33, 37, 41, 0.72);
  /* opacity: .8; */
  background: gray !important;
  color: #fff !important;
  /* justify-content: center; */
  /* margin: auto; */
  /* display: flex; */
  /* align-items: center; */
  /* margin-top: 10px; */
  /* margin-bottom: 25px; */
  cursor: pointer;
  margin-left: 40px;
}
.prflImg {
  margin-top: 5px;
  margin-bottom: 0px;
  margin-left: 50px;
  font-size: 14px;
  color: white;
}
.progimages {
  margin-top: -20px;
  width: 80px;
  height: 80px !important;
  border-radius: 40px !important;
}
