.partner_head {
  text-align: center;
  /* font-family: Montserrat; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.2;
  color: white;
  padding-bottom: 5%;
}

.partnerdiv {
  padding-top: 3rem;
  padding-bottom: 5rem;
  background-color: white;
}

.fourcol {
  /* float: none;
    display: block;
    width: 90%; */
  margin-top: 2rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  color: #f7f7f7;
}

.colservice {
  margin-top: 0.6em;
  margin-right: 1%;
  margin-bottom: 0.6em;
  margin-left: 1%;
  width: 23%;
  clear: left;
  max-width: inherit;
}

.head4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  text-align: center;
  color: black;
}

.partnerpara {
  text-align: center;
  /* font-family: Roboto; */
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1.25rem;
  /* margin-bottom: 1.25rem; */
  color: black;
}

/* servicepartner */

.aboutservice {
  float: none;
  display: block;
  padding-top: 4rem;
  /* padding-bottom: 4rem; */
  background-color: white;
  /* background-position: center center; */
  background-size: cover;
}
.subabout {
  margin-left: auto;
  margin-right: auto;
  float: none;
  display: block;
  /* width: 90%; */
  max-width: 1200px;
}

.abouthead {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  color: black;
  text-align: center;
}
.aboutpara {
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1.25rem;
  /* margin-bottom: 1.25rem; */
  color: black;
}

.partnerbutton .partnerbtn {
  width: 90%;
  margin-top: 2rem;
  padding-top: 16px;
  padding-right: 10px;
  padding-bottom: 16px;
  padding-left: 10px;
  max-width: 280px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: white;
  /* background-color: #3faded; */
  border-color: rgb(69, 177, 55);
  /* border: 1px solid #000; */
  border-radius: 4px;
  font-size: 1rem;
  margin: auto;
  justify-content: center;
  display: flex;
  /* border-color: #3faded; */
}
.partnerbutton a {
  display: flex;
  /* width: 270px; */
  margin: auto;
  align-self: center;
}
.partnerbutton a:hover {
  text-decoration: none;
}
.partnerbutton .partnerbtn :hover {
  width: 91%;
  color: white;
  /* background-color: #3faded;
  border-color: #3faded; */
}
.partnerbutton .partnerbtn :active {
  width: 91%;
  color: white;
  /* background-color: #3faded;
  border-color: #3faded; */
}

.partnerbutton .partnerbtn:focus {
  border-color: rgb(69, 177, 55) !important;
  background-color: rgb(69, 177, 55) !important;

  outline: 0;
  box-shadow: 0 0 0 0rem rgb(69, 177, 55) !important;
}

.partnerimg {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  /* margin-left: 5%; */
  width: 100%;
  clear: right;
  /* max-width: inherit; */
}

/* businesspartner */

.aboutbusiness {
  float: none;
  display: block;
  /* padding-top: 1rem;
  padding-bottom: 4rem; */
  background-color: white;
  /* background-position: center center; */
  background-size: cover;
}

.subbusiness {
  margin-left: auto;
  margin-right: auto;
  float: none;
  display: block;
  /* width: 90%; */
  max-width: 1200px;
}

.businessimg {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  /* margin-left: 5%; */
  width: 100%;
  clear: right;

  /* margin-top: 2.5rem; */
  /* margin-bottom: 1.25rem; */
  /* margin-left: 5%; */
  /* width: 95%; */
  /* clear: right; */
  /* max-width: inherit; */
}

.businesshead {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  color: black;
  /* margin-left: 5%; */
  text-align: center;
}

.businesspara {
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1.25rem;
  /* margin-bottom: 1.25rem; */
  color: black;
  /* margin-left: 5%; */
}

.businessprtnrbtn .businessbtn {
  width: 90%;
  margin-top: 2rem;
  padding-top: 16px;
  padding-right: 10px;
  padding-bottom: 16px;
  padding-left: 10px;
  max-width: 280px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: white;
  /* background-color: #3faded;*/
  border-color: rgb(69, 177, 55);
  /* border: 1px solid #000; */
  border-radius: 4px;
  /* margin-left: 5%; */
  margin: auto;
  justify-content: center;
  display: flex;
  font-size: 1rem;
  /* border-color: #3faded; */
}
.businessprtnrbtn .businessbtn :hover {
  color: white;
  /* background-color: #3faded; */
  /* border-color: #3faded; */
}
.businessprtnrbtn a:hover {
  text-decoration: none;
}
.businessprtnrbtn .businessbtn:focus {
  border-color: rgb(69, 177, 55) !important;
  background-color: rgb(69, 177, 55) !important;

  outline: 0;
  box-shadow: 0 0 0 0rem rgb(69, 177, 55) !important;
}

/* openagentservice */

.aboutopenagentservice {
  float: none;
  display: block;
  /* padding-top: 4rem;
  padding-bottom: 4rem; */
  background-color: white;
  /* background-position: center center; */
  background-size: cover;
}
.subopenagentservice {
  margin-left: auto;
  margin-right: auto;
  float: none;
  display: block;
  /* width: 90%; */
  max-width: 1200px;
}

.openagenthead {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  color: black;
  text-align: center;
}
.openagentpara {
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1.25rem;
  /* margin-bottom: 1.25rem; */
  color: black;
}

.openagentbutton .openagentservicebtn {
  width: 90%;
  margin-top: 2rem;
  padding-top: 16px;
  padding-right: 10px;
  padding-bottom: 16px;
  padding-left: 10px;
  max-width: 280px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: white;
  /* background-color: #3faded;*/
  border-color: rgb(69, 177, 55);
  /* border: 1px solid #000; */
  border-radius: 4px;
  /* margin-left: 5%; */
  margin: auto;
  justify-content: center;
  display: flex;
  font-size: 1rem;
  /* border-color: #3faded; */
}
.openagentbutton .openagentservicebtn :hover {
  width: 91%;
  color: white;
  background-color: #3faded;
  border-color: #3faded;
}

.openagentbutton a {
  display: flex;
  width: 270px;
  margin: auto;
  align-self: center;
}
.openagentbutton a:hover {
  text-decoration: none;
}
.openagentbutton .openagentservicebtn :active {
  width: 91%;
  color: white;
  background-color: #3faded;
  border-color: #3faded;
}
.openagentbutton .openagentservicebtn:focus {
  border-color: rgb(69, 177, 55) !important;
  background-color: rgb(69, 177, 55) !important;

  outline: 0;
  box-shadow: 0 0 0 0rem rgb(69, 177, 55) !important;
}
.openagentimg {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  /* margin-left: 5%; */
  width: 100%;
  clear: right;
  /* max-width: inherit; */
}

/* service partner */

/* .servicepartner {
  float: none;
  display: block;
  
  padding-bottom: 4rem;
  background-color: white;
  
  background-size: cover;
} */
.subservicepartner {
  margin-left: auto;
  margin-right: auto;
  float: none;
  display: block;
  /* width: 90%; */
  max-width: 1200px;
}

.servicepartnerhead {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
  margin-top: 2.5rem;
  /* margin-bottom: 1.25rem; */
  color: black;
  /* margin-left: 5%; */
  text-align: center;
}
.servicepartnerpara {
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1.25rem;
  /* margin-bottom: 1.25rem; */
  color: black;
  /* margin-left: 5%; */
}

.servicepartnerbutton .servicepartnerbtn {
  width: 90%;
  margin-top: 2rem;
  padding-top: 16px;
  padding-right: 10px;
  padding-bottom: 16px;
  padding-left: 10px;
  max-width: 280px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: white;
  /* background-color: #3faded;*/
  border-color: rgb(69, 177, 55);
  border-radius: 4px;
  font-size: 1rem;
  /* margin-left: 5%; */
  margin: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
}
.servicepartnerbutton .servicepartnerbtn :hover {
  width: 91%;
  color: white;
  /* background-color: #3faded; */
  /* border-color: #3faded; */
}
.servicepartnerbutton a:hover {
  text-decoration: none;
}
.servicepartnerbutton .servicepartnerbtn :active {
  width: 91%;
  color: white;
  /* background-color: #3faded; */
  /* border-color: #3faded; */
}
.servicepartnerbutton .servicepartnerbtn:focus {
  border-color: rgb(69, 177, 55) !important;
  background-color: rgb(69, 177, 55) !important;

  outline: 0;
  box-shadow: 0 0 0 0rem rgb(69, 177, 55) !important;
}

.servicepartnerimg {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  /* margin-left: 5%; */
  width: 100%;
  clear: right;
  /* max-width: inherit; */
}

/* ********************************* */

.worksmain {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: black;
  background-size: cover;
}
/* .card-body {
  height: 150px;
} */

.workshead {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
  /* margin-top: 2.5rem;
    margin-bottom: 1.25rem; */
  color: #f7f7f7;
  text-align: center;
}
.partnerfirsttext {
  font-size: 16px;
  margin-top: -5px;
  text-align: justify;
}
.partnerfirsthd {
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 15px;
}

.card_servicepartner {
  margin-top: 20px !important;
}
.workstep {
  float: none;
  display: block;
  width: 90%;
  margin-top: 2rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}

.worksteps {
  float: none;
  display: block;
  width: 90%;
  margin-top: 2rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}

.worksimg {
  float: none;
  display: block;
  width: 100%;
}

.howit {
  text-align: center;
  margin-top: 50px;
  padding-left: 18px;
}

.headline {
  float: none;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 3rem;
  padding-right: 5%;
  padding-bottom: 3rem;
  padding-left: 5%;
  background-color: #2faded;
}

.subheadline {
  float: none;
  /* display: block; */
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  min-height: 1rem;
  max-width: 1000px;
}
.headlinehead {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  color: #2d2d2d;
}

.headpara {
  color: white;
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1.25rem;
  /* margin-bottom: 1.25rem; */
  color: #f7f7f7;
}

.partner_icon {
  margin-top: 95px;
}

@media (min-width: 576px) and (max-width: 991px) {
  .partner_icon {
    margin-left: 70px;
  }
  .headlinehead {
    margin-left: -75px;
  }
  .headpara {
    margin-left: -75px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .partner_icon {
    margin-top: 0px;
  }
}
@media (min-width: 320px) and (max-width: 950px) {
  .aboutservice {
    padding-top: 0rem;
  }
  .aboutopenagentservice {
    padding-top: 0rem;
  }
  .fleetmanagement {
    padding-top: 0rem;
  }
  .aboutbusiness {
    padding-top: 0rem;
  }
}

/*======= Fleet Management- done by  jimsa========================================== */

.Fleetmanagement {
  padding-top: 5%;
  padding-bottom: 10%;
  /* background-image: url('../../assets/img/driverback.png'); */
  /* background-color: #0d334a; */
  background-position: bottom;
  background-size: cover;
  /* background-attachment:fixed; */
  width: 100%;
  height: 60%;
}

.Fleetmanagement_head {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.4;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  text-align: center;
  color: white;
}

.Fleetmanagementpara {
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1.25rem;
  color: white;
  padding-left: 4%;
  padding-right: 4%;
}

.Fleetdivpartner {
  text-align: center;
}
.Fleetmanagementbutton .Fleetmanagementbtn {
  width: 90%;
  margin-top: 2rem;
  padding-top: 16px;
  padding-right: 10px;
  padding-bottom: 16px;
  padding-left: 10px;
  max-width: 280px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: white;
  /* background-color: #3faded; */
  border-color: rgb(69, 177, 55);
  border-radius: 4px;
  font-size: 17px;
  cursor: pointer;
  /* margin-left: 5%; */
  /* margin: auto;
  float: none;
  display: block;  */
}
.Fleetmanagementbutton .Fleetmanagementbtn:hover {
  width: 91%;
  color: white;
  background-color: #3faded;
  border-color: #3faded;
  text-decoration: none;
}
.Fleetmanagementbutton .Fleetmanagementbtn :active {
  width: 91%;
  color: white;
  background-color: #3faded;
  border-color: #3faded;
}

/*======= Fleet Management-  by  jimsa========================================== */

/*======= Card implementation- done by  jimsa========================================== */

/* .partnerpageCardDark {
  margin-left: 3%;
  margin-right: 3%;
} */

/* .CardDark .card {
  min-height: 400px;
  text-align: center;
} */
.partnerpageCardDark .card-body {
  flex: 0 1 auto;
  padding: 0.5rem;
  height: 170px;
}
.partnerfullres{
  background-color: #fff;
}
/* .partnerfullres .row {
  margin-right: 5px !important;
  margin-left: 5px;
} */
