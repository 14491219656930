.aboutSec {
  display: block;

  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #0091db;
}
.abthead {
  color: white;
  font-family: 'PT Sans', Tahoma, Verdana, sans-serif;
  font-weight: 600;
  font-size: 2.8rem;
}
.abtpara {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1.25rem;
}
.abtbtn {
  float: none;
  display: block;

  background-color: black;
  font-family: 'PT Sans', Tahoma, Verdana, sans-serif;

  border-radius: 5px;
  font-size: 18px;
}
.abtbtn:hover {
  background-color: white;
  color: black;
  font-size: 18px;
  border-radius: 5px;
}

.aboutmain {
  float: none;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  /* background-color: #0091db; */
}
.submain {
  float: none;
  display: block;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  background-repeat: repeat;
  max-width: 1200px;
}
.subdiv {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  min-height: 1rem;
  max-width: 1200px;
}
.flussohd {
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;

  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.flussopara {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  /* line-height: 1.8; */
  /* margin-top: 1.25rem; */
  color: rgba(255, 255, 255, 0.8);
  text-align: justify;
  /* text-align: left;
  color: rgba(255, 255, 255, 0.8);
  font-family: Roboto;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1.25rem; */
}
.flussoparareg {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  /* line-height: 1.8; */
  /* margin-top: 1.25rem; */
  color: rgba(255, 255, 255, 0.8);
  /* text-align: justify; */
}

.flussobtn {
  font-family: Montserrat;
  text-align: center;
  color: white;
  background-color: black;
  border-radius: 4px;

  font-size: 18px;
  float: none;
  display: block;
  width: 90%;
  margin-top: 2rem;
  /* padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-left: 20px; */
  max-width: 170px;
  /* 
  outline: none;
  cursor: pointer;
  transition-property: color, background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  float: none;
  display: block;
  width: 90%;
  margin-top: 2rem;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  max-width: 170px;
  font-family: Montserrat;
  text-align: center;
  color: white;
  background-color: black;
  border-radius: 4px; */
}

.teammain {
  display: block;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: white;
  box-sizing: border-box;
  font-family: 'PT Sans', Tahoma, Verdana, sans-serif;
  content: '';
  display: table;
}
.appdownloadhead {
  font-family: 'Montserrat', sans-serif;
  color: black;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  /* color: black;
  font-size: 1.4rem;
  font-family: 'Montserrat', sans-serif; */
}
.appdownloadpera {
  color: #66615b;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  margin-top: 35px;
  text-align: justify;
}
.teamsub {
  float: none;
  display: block;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  box-sizing: border-box;
  font-family: 'PT Sans', Tahoma, Verdana, sans-serif;
}
.teamdiv {
  opacity: 1;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  transition: opacity 0.1s linear;
  word-break: break-word;
  box-sizing: border-box;
}
.teamhead {
  text-align: center;

  font-weight: 600;
  font-size: 2rem;
  line-height: 1.2;
  margin-top: 0 !important;
  margin-bottom: 1.25rem;
  color: #2d2d2d;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  box-sizing: border-box;
  word-break: break-word;
  /* font-family: "PT Sans", Tahoma, Verdana, sans-serif; */
  font-family: 'Montserrat', sans-serif;
}
.teampartners {
  float: none;
  display: block;
  width: 90%;
  /* margin-top: 80px; */
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  font-family: 'PT Sans', Tahoma, Verdana, sans-serif;
  content: '';
  display: table;
  clear: both;
}
/* .person1 {
  margin-top: 0.6em;
  margin-bottom: 0.6em;
  margin-left: 0;
  width: 31.996666666666666%;
  clear: left;
  max-width: inherit;
  float: left;
  display: block;
  box-sizing: border-box;
  font-family: "PT Sans", Tahoma, Verdana, sans-serif;
  order: 9999;
} */
.teamimg1 {
  float: none;
  display: block;
  width: 150px;
  margin-right: auto;
  margin-left: auto;

  background-position: center center;
  background-size: cover;
  min-height: 150px;
  border-radius: 50%;
  box-sizing: border-box;
}
.namesree {
  text-align: center;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 1.6;
  text-transform: uppercase;
  letter-spacing: 0.07rem;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-bottom: 1.25rem;
  color: #2d2d2d;
  /* font-family: "PT Sans", Tahoma, Verdana, sans-serif; */
}
.imgpara1 {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  color: #2d2d2d;
}
.imgdiv1 {
  float: none;
  display: block;
  width: 90%;
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
  /* font-family: "PT Sans", Tahoma, Verdana, sans-serif; */
}
.iconset1 {
  float: none;
  display: block;
  width: 100%;
  /* vertical-align: top; */
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 100px;

  padding-bottom: 20px;
}
.icontwi {
  display: inline-block;
  position: relative;
  overflow: hidden;
  max-width: inherit;

  vertical-align: inherit !important;
}
/* .icontwitter {
  margin-top: 0;
  margin-right: 1%;
  margin-bottom: 0;
  margin-left: 0;
  width: 49.5%;
  clear: left;
  max-width: inherit;
  float: left;
  display: block;
} */
/* .twitterdiv {
  outline: none;
  float: none;
  display: block;
  width: 40px;
  margin-right: auto;
  margin-left: auto;
  fill: #cfd0d1;
  transition: opacity 0.5s ease;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  height: 100%;
} */
.twittercol {
  margin-left: 20px;
  margin-right: 20px;
}
.linkindiv {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 49.5%;
  clear: none;
  max-width: inherit;
  float: left;
  cursor: pointer;
  float: left;
}
.teamlast {
  display: block;
  width: 100%;
  /* padding-top: 5rem; */
  padding-bottom: 5rem;
  background-color: white;
}
.featuremain {
  float: none;
  display: block;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #f4f4f4;
  line-height: 20px;
}
.featurehd {
  /* float: none; */
  /* display: block; */
  /* width: 90%; */
  margin-right: auto;
  margin-bottom: 2rem;
  margin-left: auto;
  /* min-height: 1rem; */
  font-family: 'PT Sans', Tahoma, Verdana, sans-serif;
  text-align: center;
  /* margin-bottom: 0 !important; */
  margin-top: 0 !important;
  /* font-family: Montserrat; */
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
  /* margin-block-start: 1em; */
  /* margin-block-end: 1em; */
  /* margin-inline-start: 0px; */
  /* margin-inline-end: 0px; */
  /* word-break: break-word; */
  color: #2d2d2d;
}
.featureimg {
  float: none;
  display: block;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  content: '';
  display: table;
  clear: both;
  flex-basis: 0;
}
.logo1 {
  /* width: 100%; */
  /* max-width: 190px; */
  border: 0;
  line-height: 0;
}
.logo2div {
  margin-top: 0.6em;
  margin-right: 1%;
  margin-bottom: 0.6em;
  margin-left: 0;
  width: 24.25%;
  clear: none;
  max-width: inherit;
  float: left;
  display: block;
}
.logo2 {
  float: none;
  display: block;
  margin-right: auto;
  /* margin-left: auto; */
  max-width: 190px;
  /* width: 100%; */
  /* max-width: 100%; */
  line-height: 0;
}
.lastwhite {
  min-height: 100%;
  margin: 0 auto -50px;
  padding-bottom: 50px;
}
.whiteclr {
  background-color: white;
  height: 50px;
}
.featuresub {
  float: none;
  display: block;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  background-repeat: repeat;
  max-width: 1200px;
}
@media (max-width: 767px) and (min-width: 300px) {
  .icontwitter {
    margin-left: 25px;
  }
  .iconlinkin {
    margin-left: 25px;
  }
  .flussohd {
    margin-left: 20px;
  }
  .flussopara {
    margin-left: 20px;
  }
  .flussoparareg {
    margin-left: 20px;
  }
  .appdownloadhead {
    margin-left: 30px;
  }
  .appdownloadpera {
    font-size: 1rem;
    margin-top: 5px;
    text-align: justify;
    margin-left: 30px;
  }
  .aboutmain {
    float: none;
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0px;
    padding-bottom: 3rem;
  }
  .teammain {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
}
.appriderlogo {
  /* margin-top: 2.5rem; */
  width: 40%;
}
.appdriverlogo {
  /* margin-top: 2.5rem; */
  width: 40%;
}
.contactenquiry {
  font-size: 1rem !important;
  margin-top: 10px;

  margin-bottom: 0.5rem;
  font-family: 'Roboto', sans-serif;
}

.appplaystoreImg {
  width: 60%;
  /* height: 21%; */
  margin-top: 10px;
}
.appiosplaystoreImg {
  width: 60%;
  margin-top: 10px;
}
.contactusform {
  /* background-color: black; */
  height: 620px;
  margin-left: 20px;
  /* margin-top: 50px; */
}
.contactusformlabel {
  margin-top: 10px;
}
.contactusformhead {
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.2;
  /* margin-top: 2.5rem; */
  /* margin-bottom: 1.25rem; */

  /* font-size: 1.4rem; */
  color: white;
  /* font-family: 'Montserrat', sans-serif; */
  margin-top: 18px;
  text-align: center;
}

.contactusformbtn {
  margin-top: 25px;
  justify-content: center;
  align-items: center;
  /* margin-left: 35%; */
  padding-left: 35%;
  padding-right: 34%;
  background-color: rgb(69, 177, 55);
  box-shadow: 1px solid rgb(69, 177, 55);
  border: 1px solid rgb(69, 177, 55);
  color: white;
}
.contactusformbtn:hover {
  margin-top: 25px;
  justify-content: center;
  align-items: center;
  /* margin-left: 35%; */
  padding-left: 35%;
  padding-right: 34%;
  background-color: white;
  box-shadow: 1px solid white;
  border: 1px solid white;
  color: black;
}
/* .quickconnectcaptcha {
 
  width: 100%;
} */
.contactusdetails {
  margin-top: 20%;
}
.g-recaptcha {
  margin-top: 20px;
}
.caontactcarousalimg {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5%;
  width: 100%;
  clear: right;
  /* max-width: inherit; */
}

@media screen and (max-width: 1199px) and (min-width: 768px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
@media screen and (max-width: 384px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
/* @media screen and (max-width: 576px) and (min-width: 450px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(1.2);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
} */
.aboutpagefullres .row {
  margin-right: 5px !important;
  margin-left: -15px;
}

@media screen and (max-width: 449px) and (min-width: 385px) {
  .submain {
    float: none;
    display: block;
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    background-repeat: repeat;
    max-width: 1200px;
  }
}
@media screen and (max-width: 360px) and (min-width: 320px) {
  .submain {
    float: none;
    display: block;
    width: 98%;
    margin-right: auto;
    margin-left: auto;
    background-repeat: repeat;
    max-width: 1200px;
  }
}
@media screen and (max-width: 697px) and (min-width: 576px) {
  .submain {
    float: none;
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-repeat: repeat;
    max-width: 1200px;
  }
}
@media screen and (max-width: 575px) and (min-width: 493px) {
  .submain {
    float: none;
    display: block;
    width: 82%;
    margin-right: auto;
    margin-left: auto;
    background-repeat: repeat;
    max-width: 1200px;
  }
}
@media screen and (max-width: 420px) and (min-width: 410px) {
  .subdiv {
    width: 92%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 409px) and (min-width: 395px) {
  .subdiv {
    width: 96%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 409px) and (min-width: 385px) {
  .subdiv {
    width: 97%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 384px) and (min-width: 370px) {
  .subdiv {
    width: 89%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 364px) and (min-width: 361px) {
  .subdiv {
    width: 92%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 360px) and (min-width: 350px) {
  .subdiv {
    width: 86%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 420px) and (min-width: 385px) {
  .submain {
    float: none;
    display: block;
    width: 98%;
    margin-right: auto;
    margin-left: auto;
    background-repeat: repeat;
    max-width: 1200px;
  }
}
@media screen and (max-width: 1890px) and (min-width: 1250px) {
  .subdiv {
    width: 93%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 1249px) and (min-width: 1230px) {
  .subdiv {
    width: 94%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 1229px) and (min-width: 1200px) {
  .subdiv {
    width: 97%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 1199px) and (min-width: 1065px) {
  .subdiv {
    width: 87%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 1064px) and (min-width: 1010px) {
  .subdiv {
    width: 92%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}

@media screen and (max-width: 1009px) and (min-width: 992px) {
  .subdiv {
    width: 94%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 991px) and (min-width: 800px) {
  .subdiv {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 799px) and (min-width: 768px) {
  .subdiv {
    width: 83%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 575px) and (min-width: 560px) {
  .subdiv {
    width: 79%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 559px) and (min-width: 530px) {
  .subdiv {
    width: 84%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 529px) and (min-width: 510px) {
  .subdiv {
    width: 88%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 492px) and (min-width: 480px) {
  .subdiv {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 430px) and (min-width: 421px) {
  .subdiv {
    width: 92%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 335px) and (min-width: 325px) {
  .subdiv {
    width: 93%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
@media screen and (max-width: 324px) and (min-width: 320px) {
  .subdiv {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    min-height: 1rem;
    max-width: 1200px;
  }
}
.contactpagebtn {
  width: 55%;
  margin-top: 15px;
  /* padding-top: 16px; */
  padding-right: 10px;
  /* padding-bottom: 16px; */
  padding-left: 10px;
  /* max-width: 280px; */
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: white;
  /* background-color: #3faded; */
  background-color: rgb(69, 177, 55);
  box-shadow: 1px solid rgb(69, 177, 55);
  border: 1px solid rgb(69, 177, 55) !important;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}
.contactpagebtn:hover {
  background-color: rgb(69, 177, 55);
  box-shadow: 1px solid rgb(69, 177, 55);
  border: 1px solid rgb(69, 177, 55) !important;
  color: white !important;
  border: 1px solid rgb(69, 177, 55);
}
.contactpagebtn:active {
  background-color: rgb(69, 177, 55) !important;
  box-shadow: 1px solid rgb(69, 177, 55) !important;
  border: 1px solid rgb(69, 177, 55) !important;
}
.contactpagebtn:focus {
  border-color: rgb(69, 177, 55) !important;
  background-color: rgb(69, 177, 55) !important;

  outline: 0;
  box-shadow: 0 0 0 0rem rgb(69, 177, 55) !important;
}
@media screen and (max-width: 767px) and (min-width: 320px) {
  .appriderlogo {
    width: 30%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
  }
  .appplaystoreImg {
    width: 40%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
    margin-bottom: 10px;
  }
  .appiosplaystoreImg {
    width: 40%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
    margin-bottom: 10px;
  }
  .appdriverlogo {
    width: 30%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
  }
}
.contactcarou {
  margin-top: 13%;
  margin-left: 20px;
}
.contactcarou .carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 0px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.contactcarou .carousel-control-next,
.carousel-control-prev {
  z-index: 0;
}
@media screen and (max-width: 1240px) and (min-width: 1200px) {
  .rc-anchor-normal {
    height: 74px;
    width: 290px !important;
  }
}

/* .contactenquiry .form-control:focus {
  color: rgb(8, 40, 60);
  background-color: #fff;
  border-color: #00000040 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(8, 40, 60);
} */

.contactusform .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #00000040 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(8, 40, 60);
}
