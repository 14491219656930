.faqContainer .container p {
  font-family: Arial;
  font-size: 18px;
  font-style: arial;
  font-weight: 300px;
  text-decoration: none;
  text-transform: none;
  text-align: justify !important;

  color: #000000;
  background-color: #ffffff;
}
.faqContainer{ 
  background-color: #ffffff;
}
.faqmainhd {
  padding-top: 40px;
  color: black;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}
.faqHead {
  font-size: 18px;
  font-style: arial;
  text-decoration: none;
  text-align: justify;
  text-transform: none;
  color: #000000;
  background-color: #ffffff;
  padding-left: 52px;
  font-weight: 300;
}
.faqSubHead {
  color: black;
  font-size: 21px;
  font-weight: 600;
  margin-left: -45px;
}
.subfaqHead {
  margin-left: -37px;
}
.faqsubQuotes {
  color: black;
  font-size: 18px;
  font-weight: 600;
}
.pgraph {
  margin-left: 72px;
}
.list {
  margin-left: 35px;
}
.faqsecmainhd {
  padding-top: 100px;
  color: black;
  font-size: 52px;
  font-weight: 600;
  text-align: center;
}
.listpgraph {
  margin-left: 82px;
}
