.OpnAgntTable table {
  border: 1px solid rgb(209, 208, 208);
}
.OpnAgntTable th {
  padding: 0.5rem;
  font-size: 16px;
  /* height: 40px; */
  text-align: left;
  vertical-align: middle;
  /* background-color: #2faded; */
  color: white;
  font-family: 'Roboto', sans-serif;
}
.OpnAgntTable td {
  vertical-align: middle;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}
/* .opnAgntTblScrol {
  overflow: scroll;
  height: 580px;
  overflow-x: hidden;
  width: 100%;
} */

.opnAgntviewIcon {
  width: 17px;
  color: rgb(105, 103, 103);
  cursor: pointer;
}
.vImage {
  width: 50px;
}
.noDataImg {
  width: 50px;
  height: 55px;
}
.noDataImgDiv {
  text-align: center;
}
.noDataTextDiv {
  text-align: center;
}
.openCap {
  text-transform: capitalize;
}
.opnAgntdriverimg {
  width: 40px;
  margin-left: 0px;
  border-radius: 50px;
}
.searchDiv{
  padding-top: 10px;
  padding-bottom: 20px;
}
.cmnMainTableHd th {
  background-color: rgb(13, 51, 74);
  color:white;
 }
 
 .cmnMainTableHd {
   background-color: rgb(13, 51, 74);
 }
 .cmnViewIcon {
  cursor: pointer;
  color: #233e56 !important;
}
.paginationDiv {
  justify-content: center;
  display: flex;
}

.paginationDiv .pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.paginationDiv .pagination > .active > a {
  background-color: #2199e8;
  border-color: #2199e8;
  color: #fff;
}

.paginationDiv .pagination > li > a {
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.paginationDiv .pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #2199e8;

  outline: none;
}

.paginationDiv .pagination > li > a,
.pagination > li > span {
  color: #2199e8;
}

.paginationDiv .pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.paginationDiv .pagination > .disabled > a {
  color: #96caec !important;
  cursor: default;
}

.commonInput {
  width: 100% !important;
  margin-bottom: 10px !important;
}

.commonInput input {
  padding: 0.5rem 1rem;
  padding-left: 35px;
  /* border: 1px solid #cc2026; */
  outline: none;
  box-shadow: none;
  text-align: left;
  color: black;
  width: 100% !important;
  height: 35px !important;
  /* border-radius: 30px; */
  border: 1px solid #e4e7ea;
}

.commonInput i {
  position: absolute;
}

.cmnSearchIcon {
  margin-top: 10px !important;
  margin-left: 15px !important;
  min-width: 40px;
  color: #ced4da;
}

.commonFilterDiv {
  width: 100% !important;
  margin-top: 0px !important;
}

.commonFilterSelect {
  width: 100% !important;
  height: 35px;
  background-color: white;
  border: 1px solid #e4e7ea;
  /*margin-top: -4px;*/
}

.cmnFilterForm.form-control {
  width: 245px;
  color: #5c6873;
  background-color: #fff;
  border: 1px solid #0000004d;
  border-radius: 0rem;
  display: block;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.dangerClass {
  border: red solid 1px;
}
.errormessage {
  color: red;
  text-align: left;
  margin-top: unset;
  line-height: unset;
  color: red;
  font-size: 12px;
  margin-bottom: 0px;
}
.cmnRecordIcon {
  font-size: 15px;
}

.cmnResutText {
  opacity: 0.9;
}

.cmnPageCount {
  padding: 5px;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.9;
}
.commonInput {
  width: 100% !important;
  margin-bottom: 10px !important;
}

.commonInput input {
  padding: 0.5rem 1rem;
  padding-left: 25px;
  /* border: 1px solid #cc2026; */
  outline: none;
  box-shadow: none;
  text-align: left;
  color: black;
  width: 100% !important;
  height: 35px !important;
  /* border-radius: 30px; */
  border: 1px solid #c8ced4;
}

.commonInput i {
  position: absolute;
}
