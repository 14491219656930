.termsMain {
  padding-top: 40px;
  color: black;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}
.termsContainer .container  {
  font-family: Arial;
  font-size: 18px;
  font-style: arial;
  font-weight: 300px;
  text-decoration: none;
  text-transform: none;
  text-align: justify !important;

  color: #000000;
  background-color: #ffffff;
}
.termsContainer{
  background-color: #ffffff;
}
.subQuotes {
  color: black;
  font-size: 18px;
  font-weight: 600;
}
.termsHead {
  font-size: 18px;
  font-style: arial;
  text-decoration: none;
  text-align: justify;
  text-transform: none;
  color: #000000;
  background-color: #ffffff;
  padding-left: 52px;
  font-weight: 300;
}
.termsSubHead {
  color: black;
  font-size: 21px;
  font-weight: 600;
  margin-left: -45px;
}
.subHead {
  margin-left: -37px;
}
.subStyle {
  color: #002f34 !important;
}
.forceText {
  color: black;
  font-weight: 600;
  font-size: 18px;
}
